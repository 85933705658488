import { defer, Observable, timer } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

export function polling<T>(
  task: () => Observable<T>,
  options: { count?: number; period?: number; delay?: number } = {},
) {
  const { count = 15, period = 1000, delay = 0 } = options;

  return defer(() =>
    timer(delay, period).pipe(
      take(count),
      switchMap(() => task()),
    ),
  );
}
